//fetchDataDetail
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import voucher from '@/router/routes/voucher'
export default function useReceiptConfirm() {
    const { t } = useI18nUtils();
    const toast = useToast()
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const isShowVoucher = ref(1);
    const note = ref('');
    const isShowCacelPopup = ref(false);
    const branch_id = userStorage.branch_id;
    const id = ref(router.currentRoute.params.id);
    const receipt = ref({});
    const refitemsListTable = ref(null);
    const html2Pdf = ref(null);
    const DateNow = ref( new Date().toISOString());
    const vouchers = ref([]);
    const items_cancle = ref([]);
    const total_cancle = ref(0);
    const tableColumns = [
      
        { key: 'no', label: '#', sortable: false },
        { key: 'item_name', label: t('list_service'), sortable: false },
        { key: 'qty', label: 'จำนวน', sortable: false, class:"text-center" },
        { key: 'price', label: 'ราคา/หน่วย', sortable: false },
        { key: 'discount', label: 'ส่วนลด/หน่วย', sortable: false , class:"text-center"  },
        { key: 'total', label: 'ราคารวม', sortable: false }


    ]
    const tablePrintColumns = [
      
      
        { key: 'item_name', label: 'รายการ', sortable: false },
        { key: 'price', label: 'ราคา', sortable: false },
        { key: 'qty', label: 'จำนวน', sortable: false, class:"text-center" },
        
        { key: 'discount', label: '%ส่วนลด', sortable: false , class:"text-center"  },
        { key: 'total', label: 'รวม', sortable: false , class:"text-right"}


    ]
    const getReceipt = async ()=>{
        let res = await  store.dispatch('app-receipt/fetchDataDetail',id.value);
        if(res.data.success){
            receipt.value = res.data.data
            vouchers.value = []
            getItems();
            if(receipt.value.patients_id > 0){
                getVoucher();
              
            
            }
            


        }
        console.log('receipt-sigle',receipt.value);
        // receipt.value
         
    }
    const getItems = async ()=>{

        items_cancle.value = receipt.value.items.map((item)=>{
            if(item.cancel == 1){
                total_cancle.value += (item.total*1)
            }
            console.log('item-get',item);
          //  total_cancle.value += item.price
            return item.id;
        });

    }
    const getVoucher = async ()=>{
        let params = {
            patients_id:receipt.value.patients_id

        }
        //console.log('params',params);
        store.dispatch('app-receipt/fetchPatientVoucherData',params).then(response=>{
         
            if(response.data.success){
                //vouchers.value.voucher_balance = response.data.data[0].balance*1;
                vouchers.value = response.data.data.map((value)=>{
                    value.used = 0;
                    value.balance = value.balance*1;
                    return value;

                });
                console.log('vouchers',vouchers);
               // console.log('payment',payment.value);
                //patient.value =response.data.data[0];
                
            }
            
          }).catch((err)=>{
            console.log('err',err);
           
      
          })
    }
    const updateReceiptStatus = async()=>{
        let params = {
            id:receipt.value.id,
            status_id:4,
            note:''
        }
        console.log('params',params);
        if(receipt.value.status_id != 5){
            await  store.dispatch('app-receipt/updateReceiptStatusData',params)
        }
     
      getReceipt();
    }
    const CancelReceiptStatus = async()=>{
        let params = {
            id:receipt.value.id,
            status_id:5,
            note:note.value,
            items_cancle:items_cancle.value
        }
        console.log('params',params);
     const res =  await  store.dispatch('app-receipt/updateReceiptStatusData',params)
     if(res.data.success){
        toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })

     }else{
        toast({
            component: ToastificationContent,
            props: {
              title: "Error Cancel Recipt",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
     }
     isShowCacelPopup.value = false;
      getReceipt();
    }
    
    getReceipt();

    return{
        refitemsListTable,
        tableColumns,
        receipt,
        html2Pdf,
        tablePrintColumns,
        DateNow,
        vouchers,
        isShowVoucher,
        updateReceiptStatus,
        CancelReceiptStatus,
        isShowCacelPopup,
        note,
        items_cancle,
        total_cancle

    }
}